<template>
  <div  :style="computedStyle" v-show="!isHidden" :class="CSSClasses">
    <el-form-item
      ref="formItem"
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
    <span slot="label" :class="labelCssClasses" :style="labelCss">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-time-select
            :picker-options="timeOptions"
            v-bind:value="computedValue"
            class="time_field_width"
            :size="size"
            :placeholder="placeholder || $locale.main.placeholder.time_field"
            v-on:input="$emit('input', $event)" :name="name"
            :disabled="_isReadonly"
            >
    </el-time-select>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'
export default {
  name: 'time_field',
  mixins: [mixin, registryMixin, InputLabel],
  props: {
    label: {
      description: 'name',
      type: String
    },
    placeholder: {
      description: 'placeholder',
      type: String
    },
    tooltip: {
      description: 'tooltip',
      type: String
    },
    startTime: {
      description: 'start_time',
      type: String,
      default: '00:00'
    },
    endTime: {
      description: 'end_time',
      type: String,
      default: '23:45'
    },
    stepTime: {
      description: 'step_time',
      type: String,
      default: '00:15'
    },
    hideSeconds: {
      description: 'hide_seconds',
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedValue () {
      if (this.value && this.hideSeconds && this.value.length > 5) {
        return this.value.slice(0, -3)
      }
      return this.value
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  mounted () {
    if (this.validationNeeded) {
      this.$refs.formItem.form.validateField(this.name)
    }
  },
  data () {
    return {
      timeOptions: {
        start: this.startTime,
        end: this.endTime,
        step: this.stepTime
      }
    }
  }
}
</script>

<style scoped>
  .time_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
